.icons-usertable{
    padding: 0.6rem 0.8rem;
    background-color: #435ebe;
    border-color: #435ebe;
    border-radius: 8px;
    margin-right: 2%;
    cursor: pointer;
}
.action-btn{
    /* width: 60%; */
    display: flex;
    /* justify-content: space-between; */
}
.add-vip{
    background-color: #0075ff;
    border-radius: 8px;
    
    border-color: #0075ff;
    cursor: pointer;

color: #fff;
    padding: 0.6rem 0.8rem;
}
.affiliate-vip{
    background-color: #1a1f37;
    border-radius: 8px;
    border: 2px solid #1a1f37 ;
    cursor: pointer;

color: #fff;
    padding: 0.6rem 0.8rem;
}
.remove-vip{
    background-color: #dc3545;
    border-radius: 8px;
    border-color: #dc3545;
    cursor: pointer;


color: #fff;
    padding: 0.6rem 0.8rem;
}
.color2{
    background-color: #198754;
    border-color: #198754;
}
.color3{
    background-color: #dc3545;
    border-color: #dc3545;
}
.toggle-Button{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5%;

}
.toggle-Button2{

  width: 100%;
  margin-top: 5%;

}
.dev2{
    height: 45px;
    width: 100%;
    cursor: pointer;
    background: #0075ff;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5%;
    font-weight: 700;


    /* line-height: 118.3%; */
    /* text-align: center; */
    color: #cbd8e7;
    /* padding: 0px 28px; */
    /* margin-right: 16px; */
    border: 1px solid #0075ff;
}
.dev2:hover{
    background: #2e363c;
    border: 1px solid #2e363c;

}
.dev{
  height: 40px;
  width: 48%;

  cursor: pointer;
  background: #0075ff;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 700;

  /* line-height: 118.3%; */
  /* text-align: center; */
  color: #cbd8e7;
  /* padding: 0px 28px; */
  /* margin-right: 16px; */
  border: 1px solid #0075ff;
}
.dev:hover{
  background: #2e363c;
  border: 1px solid #2e363c;

}

   
.pro{
    height: 40px;
    cursor: pointer;
    width: 48%;
    background: #0075ff;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    font-weight: 700;

    /* line-height: 118.3%; */
    /* text-align: center; */
    color: #cbd8e7;
    /* padding: 0px 28px; */
    /* margin-right: 16px; */
    border: 1px solid #0075ff;

}
.pro:hover{
    background: #2e363c;
    border: 1px solid #2e363c;

}
.preset-icon{
    width: 70px;
    height: 70px;
    margin-bottom: 2%;
}
.preset-icon img{
    width: 100%;
}
.btnChangeAvatar{
    height: 30px;
    width: 120PX;
    cursor: pointer;
    background: #0075ff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #cbd8e7;
    border: 1px solid #0075ff;
}
.wrap-preset-input{
    display: flex;
    flex-direction: column;
    margin-top: 2%;

}
.wrap-preset-input label{
    margin: 0;
    font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
    margin-bottom: 1%;
    font-weight: 500;
}
.wrap-preset-input input{
    width: 100%!important;
    height: 2.3rem;
    font-size: 0.875rem!important;
    font-weight: 400!important;
    line-height: 1.4!important;
    background-color: transparent;
    border: 2px solid #4a5568;
    border-radius: 10px;
    color:#fff;
    padding-left: 2%;
}
.wrap-array-input{
    display: flex;
    flex-direction: column;
    margin-top: 2%;
}
.wrap-array-input label{
    margin: 0;
    font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
    margin-bottom: 1%;
    font-weight: 500;
}
.wrap-array-input input{
    width: 100%!important;
    height: 2.3rem;
    font-size: 0.875rem!important;
    font-weight: 400!important;
    line-height: 1.4!important;
    background-color: transparent;
    border: 2px solid #4a5568;
    border-radius: 10px;
    padding-left: 2%;
    color: #fff;

}
.remove-tag{
    position: absolute;
    /* left: 0; */
    right: 9px;
    top: 5px;
}
.add-tag{
    height: 20px;
    width: 100px;
    cursor: pointer;
    background: #0075ff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #cbd8e7;
    border: 1px solid #0075ff;
}
.final-submit{
    height: 30px;
    width: 100%;
    cursor: pointer;
    background: #0075ff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #cbd8e7;
    border: 1px solid #0075ff;
    margin-top: 2%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
::-webkit-scrollbar {
    display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 1;
    filter: invert(1);
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }
  .selectTheme_wrapper{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
  }
  .selectOption_left{
    width: 49%;
  }
  .selectOption_right{
    width: 49%;
    height: 4px;
  }
  .selectOption_right h3{
    color: white;
    font-size: 16px;
  }
  .preset21{
    display: flex;
    flex-direction: row !important;
    width: 70%;

    margin-top: 2%;
  }
  .wrap-fileType{
    display: flex;
    flex-direction: row !important;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2%;
  }
  .filType{
    width: 40px;
    display: flex;
    justify-content: center;
    margin-right: 4%;
    align-items: center;
    background-color: #072649 !important;
    cursor: pointer;
margin-bottom: 5px;
    padding: 2%;
    border-radius: 5px;
  }
  .wrap-fileType .filType-ChoseColor{
    height: 46px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    margin-right: 4%;
    align-items: center;
    cursor: pointer;
margin-bottom: 3px;
    padding: 2%;
    border-radius: 5px;
  }
   .filType-ChoseColor.active{
   border: 4px solid white;
  }
  .preset-radio{
    margin-right: 4%;
  }
  .preset-radio label{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #fff;
  }
  .checkbox{
    width: 18px;
    height: 18px;
    margin-left: 20%;
  }
  .active69{
    width: 40px;
    display: flex;
    background-color: #0075ff;
cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 2%;
    margin-right: 4%;
    border-radius: 5px;
    margin-bottom: 4px;
  }
  .icons-wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 2px solid #989494;
    padding-bottom: 1%;
    border-radius: 10px;

  }
  .design-icon{
    width: 100%;
    /* background-color: #198754; */
    display: flex;
    flex-wrap: wrap;
    height: 162px;
    overflow-x: scroll;
    padding: 2%;
    border-radius: 12px;

  }
  .icon-size-d{
    width: 46px;
    height: 46px;
    filter: invert(1) brightness(10);
  }
  .icon-size-d2{
    width: 46px;
    height: 46px;
    /* filter: invert(1) brightness(10); */
  }
  .rti--container{
    width: 100% !important;
    border: 2px solid #4a5568 !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    padding: 6px !important;
  }
  .rti--input{
    background-color: transparent !important;
    font-size: 0.875rem!important;
    font-weight: 400!important;
    color: #ffff !important;
    width: 30% !important;
  }
  .rti--tag{
    background-color: #072649 !important;
    font-size: 0.875rem !important;
    color: #fff !important;
    padding-left: 1% !important;
  }
  .rti--tag button{
    color: #fff
  }
  .we{
    filter: invert(1) brightness(10);
  }
  .final-icon{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
  
    align-items: center;
    /* background-color: #072649 !important; */
    cursor: pointer;
    padding: 2%;
    border-radius: 5px;

  }
  /* .final-icon img{
    width: 100%
  } */
  /* .submit-design-p{
    width: 30%;
    margin: auto;
  } */
  .csv{
    width: 100%;
    border-radius: 8px;
  }
  .csv img{
    width: 72%;
    margin-top: 4%;
    border-radius: 8px;
  }
  .bulk-wrap{
    width: 100%;
    /* background-color: red; */
    /* margin: auto; */
    /* flex-direction: column; */
    /* display: flex;
    
    justify-content: center; */
  }
  .sx-flex{
    display: flex;
    justify-content: space-between;
  }
  .sx2-flex{
    width: 16%;

  }
  .filType3 {
    display: flex;
    justify-content: center;
    margin-right: 4%;
    align-items: center;
    background-color: #072649 !important;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 2%;
    border-radius: 5px;
  }
  .wrap-platform{
    width: 29%;
    display: flex;
    flex-direction: column;
  }
  .active696 {
    display: flex;
    justify-content: center;
    margin-right: 4%;
    align-items: center;
    background-color: #0075ff !important;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 2%;
    border-radius: 5px;
  }
  .userData{
    width: 100%;
    cursor: pointer;
    /* background-color: #072649; */
    /* border: 1px solid #fff; */
    margin-top: 2%;
  }
  .userData:hover{
    background: linear-gradient(182deg, rgb(6 7 16 / 94%) 19.41%, rgb(35 46 103 / 50%) 76.65%);
}
  
  .wrap-userData{
    width: 100%;
    height: 250px;
    overflow: scroll;
    margin-top: 3%;
    border: 1px solid;
    border-radius: 8px;

    background: linear-gradient(127.09deg, rgba(24, 29, 60, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
  }
  .abc34{
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .selected-User{
    width: 100%;
    /* height: 250px; */
    /* overflow: scroll; */
    margin-top: 3%;
    border: 1px solid;
    border-radius: 8px;
    background: linear-gradient(127.09deg, rgba(24, 29, 60, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
  }
  .wrap-selected-user{
    width: 100%;
    margin-top: 11%;
    display: flex;
    flex-direction: column;
  }
  .dic-upload{
    margin: auto;
    width: 21%;
  }
  .my-custom-input-home{
    width: 100%;
    background-color: #0f1535 !important;
    pointer-events: auto;
    color: #ffffff !important;
    border-radius: 0.9375rem !important;
    border: 0.5px solid #fd5c70 !important;
    padding: 0.5rem 2.75rem !important;
}
.my-custom-input-home2{
  width: 100%;
  background-color: #0f1535 !important;
  pointer-events: auto;
  color: #ffffff !important;
  border-radius: 0.9375rem !important;
  border: 0.5px solid #4a5568 !important;
  padding: 0.5rem 2.75rem !important;
}
.react-tel-input .selected-flag {
  background-color: transparent !important;

}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}
.icons-usertable:hover,
.icons-usertable:focus,
.icons-usertable:active {
    transform: scale(1.1);
}
.wrap-user-profile-image{
  width: 50%;
  height: auto;
  display: flex;
  justify-content:space-between;

}
.user-profile-image{
  width: 72px !important;
  height: 72px !important;
  border-radius: 8px;
  border: 2px solid #30d472;
  
}
.user-profile-image img {
  width: 100%;
  border-radius: 7px;
  height: 100%;

  object-fit: cover;


}
.user-information{
width: 81%;

}
.user-information h1{
  /* margin: 0; */
    font-family: "Plus Jakarta Display", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.625;
  
    color: #ffffff;
    font-weight: 700;
}
.section-content-post{
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between ;
  
}
.wrap-content-post{
  width: 23%;
  height: 260px; 
  position: relative;
margin-bottom: 2%;
}
.wrap-content-post img{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.content-type{
  width: 24px;
  height: 24px;
  position: absolute;
  right: 14px;
  top:3px
}
.user-information a{
  font-family: "Plus Jakarta Display", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.625;

  color: #ffffff;
  font-weight: 700;
}
.user-information a:hover{
  color: #0075ff;
}
.atagin{
  font-family: "Plus Jakarta Display", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.625;

  color: #ffffff;
  font-weight: 600; 
}
.atagin:hover{
  color: #0075ff;

}
.wrap-coverPhoto{
  display: flex;
  width: 38%;
  justify-content: space-between;
}
.cover-bio{
  height: 100px;
  object-fit: cover;
  width: 100% !important; 
  border-radius: 12px;
}
.preset-icon22 {
  width: 100%;
  height: 100px;
  /* height: 70px; */
  /* background: aliceblue; */
  margin-bottom: 2%;
}
.image-avatar-cover{
  width: 42%;
}
.tag-input-div .rti--container {
  overflow: scroll !important;
  height: 110px !important;
  background-color: #0f1535 !important;
}
.field-phone-code {
  display: flex;
  height: 48px;
  width: 100%;
  /* border-radius: 8px; */
  /* border: 2px solid #1f3137; */
  padding: 8px;
  
  background-color: #0f1535 !important;
  
  color: #ffffff !important;
   border-radius: 8px;

  border: 0.5px solid #4a5568;
  font-style: normal;
  font-weight: 500;
  line-height: 98.8%;

  
}
.field-phone-code input:focus-visible {
  outline: none !important;
}
.field-phone-code input {
  width: 100%;
  border: none;
  background: transparent;
  color: #ffffff;
  padding: 8px;

  font-family: "Alliance No.1";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 98.8%;
}
.phone-code {
  border-radius: 4px;
  background: #273cc1;
  font-size: 16px;

  padding: 6px;
  cursor: pointer;
}
.pr {
  position: relative;
}
.phone-dropdown {
  width: 100%;
  background: #0f1535;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 6px 6px;
  scrollbar-width: none;
  border: 2px solid #1f3137;
  position: absolute;
z-index: 1000;
  margin-top: 2%;
}
.phone-dropdown-list {
  padding: 7px 9px;
  display: flex;
  cursor: pointer;
}
.phone-dropdown-list:hover {
  background: #1f3137;
}
.phone-dropdown-list2:hover {
  background: #17403e !important ;
}
.phone-dropdown-img img {
  width: 16px;
  height: 11px;
}
.phone-dropdown-code {
  display: flex;
  color: #6b7280 !important;
}
.phone-dropdown-code p {
  margin-left: 10px;
  font-size: 16px;
}